@import "../style/variables";

.Container {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-content: flex-start;
    max-height: 100vh;
    max-width: 500px;
}

.Flux {
    padding: 15px;
    padding-bottom: 0;
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.Chat {
    border: 1px solid $grey-5;
    padding: 10px 15px;
    background: white;
}