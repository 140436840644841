@import "../style/variables";

.Link {
  display: block;
  border-bottom: 1px solid $grey-4;
  padding: 10px;
  &:hover {
    background: $grey-4;
    padding-bottom: 9px;
    border-bottom: 2px solid $grey-6;
  }
}

.ActiveLink {
  @extend .Link;
  color: $pink;
  font-weight: bold;
  background: $grey-2;
}

.Text {
  display: block;
}

