@import "../style/variables";

.Link {
  a {
    display: block;
    padding: 1em;
    margin-left: -1em;

    &:hover {
      background: $grey-4;
    }
  }
}
