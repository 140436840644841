@import "../style/variables.scss";

.App {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 75px 1fr;
  grid-template-areas: "SideBar Header" "SideBar Content";
  height: 100vh;
}



.Navigation {
  grid-area: SideBar;
  background: white;
  min-height: 100%;
  overflow: auto;
  position: fixed;
}



.Content {
  grid-area: Content;
  max-width: 85em;
}

@media (max-width: $break-phone) {
  .App {
    grid-template-columns: auto min-content;
    grid-template-areas: "SideBar Header" "Content Content";
  }

  .Navigation {
    min-width: 65px;
    overflow: hidden;
    position: relative;
  }
}
