@import '../style/variables';

.SmallSelect {
  .form-control {
  }
  select {
    display: inline-block;
    margin-left: 0.3rem;
    max-width: 6.5rem;
  }
}
