@import "../style/variables";

.NavigationHeader {
  color: $pink;
  font-size: 65px;
  text-align: center;
  background: white;
  line-height: 74px; // Not sure why, but this is one less than the 75px height of the header

  a.icon-my-brian {
    z-index: 10005;
  }
}

.SrOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.MenuToggle {
  display: inline-block;
  padding: 0 0 0 30px;
  line-height: 75px;
  font-size: 25px;
  color: #333;
  float:left;

  &:hover, &:focus {
    color: $pink;
  }
}

.MainMenu {
  position: absolute;
  left: -200px;
  top: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  transition: left 0.3s ease,
              box-shadow 0.3s ease;
  z-index: 999;

  ul {
    list-style: none;
    margin: 0;
    padding: 75px 0 0;
    /* Hide shadow w/ -8px while 'closed' */
    box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: white;
  }

  .MenuClose {
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px;

    &:hover, &:active {
      color: $pink;
    }
  }

  &:target,
  &[aria-expanded="true"] {
    left: 0;
    outline: none;
    box-shadow: 3px 0 12px rgba(0,0,0,.25);

    .MenuClose {
      z-index: 1001;
    }

    ul {
      position: relative;
      z-index: 1000;
    }

    + .Backdrop {
      position: absolute;
      display: block;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      background: rgba(0,0,0,.5);
      cursor: default;
    }
  }
}

@supports (position: fixed) {
  .MainMenu,
  .MainMenu:target + .Backdrop,
  .MainMenu[aria-expanded="true"] + .Backdrop {
    position: fixed;
  }
}


@media (min-width: $break-phone) {
  .MenuToggle, .MainMenu .MenuClose {
    display: none;
  }

  .MainMenu {
    position: relative;
    left: auto;
    top: auto;
    height: auto;

    ul {
      padding: 0;
      box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
  }
}
