@import "../style/variables";

.Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    max-width: 500px;
}

.Picture {
    border-radius: 50%;
    height: 100px;
}

.Gravatar {
    color: $grey-7;
    font-size: 0.7rem;
    
    a, a:visited {
        color: $pink;
    }
}

.NameNationality {
    display: flex;
    width: 100%;
    justify-content: center;
}

.Name {
    margin: 5px 0;
    padding-right: 5px;
}
.Nationality {
    padding-left: 5px;
    img {
        vertical-align: middle;
    }
}

.PublicDetails {
    font-size: 0.7rem;
    font-style: italic;

    dl {
        text-align: center;
    }
    
    dt {
        color: $pink;
        display: inline;
    }

    dd {
        display: inline;
        margin: 0 5px 0;
    }
}

.Bio {
    font-size: 0.9rem;
}