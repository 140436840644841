@import '../style/variables';



.Header {
  grid-area: Header;
  background: white;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3);
  height: 75px;
  width: 100%;
  z-index: 2;
  position: fixed;
  font-size: 24px;
  color: $pink;
  line-height: 75px;
  padding-left: 1em;
  min-width: 120px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;

}

.Right {
  float: right;
  margin-right: 1em;
  line-height: 75px;
}

.MenuToggle {
  display: inline-block;
  padding: 0 0 0 30px;
  line-height: 75px;
  font-size: 25px;
  float: left;

  &:hover,
  &:focus {
    color: $pink;
  }

}

.IconLogo{
  display: inline;
}

@media (max-width: $break-phone) {
  .Header {
    // position: relative;
    margin-left: 0;
    padding-right: 1em;
    width: 100%;
  }
}

.NavigationHeader {
  color: $pink;
  font-size: 65px;
  text-align: center;
  line-height: 74px; // Not sure why, but this is one less than the 75px height of the header

  a.icon-my-brian {
    z-index: 10005;
  }
}
.IconLogo{
  display: none;
}

@media (min-width: $break-phone) {
  .MenuToggle,
  .MainMenu .MenuClose {
    display: none;
  }

  .IconLogo{
    display: inline;
  }

  .MainMenu {
    position: relative;
    left: auto;
    top: auto;
    height: auto;

    ul {
      padding: 0;
      box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
  }
}
