@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-black';
    src: url('../fonts/roboto/Roboto-Black/Roboto-Black.eot');
    src: url('../fonts/roboto/Roboto-Black/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Black/Roboto-Black.woff') format('woff'),
        url('../fonts/roboto/Roboto-Black/Roboto-Black.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Black/Roboto-Black.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-black-italic';
    src: url('../fonts/roboto/Roboto-BlackItalic/Roboto-BlackItalic.eot');
    src: url('../fonts/roboto/Roboto-BlackItalic/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-BlackItalic/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/roboto/Roboto-BlackItalic/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-BlackItalic/Roboto-BlackItalic.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-bold';
    src: url('../fonts/roboto/Roboto-Bold/Roboto-Bold.eot');
    src: url('../fonts/roboto/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Bold/Roboto-Bold.woff') format('woff'),
        url('../fonts/roboto/Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Bold/Roboto-Bold.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-bold-italic';
    src: url('../fonts/roboto/Roboto-BoldItalic/Roboto-BoldItalic.eot');
    src: url('../fonts/roboto/Roboto-BoldItalic/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-BoldItalic/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/roboto/Roboto-BoldItalic/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-BoldItalic/Roboto-BoldItalic.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-italic';
    src: url('../fonts/roboto/Roboto-Italic/Roboto-Italic.eot');
    src: url('../fonts/roboto/Roboto-Italic/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Italic/Roboto-Italic.woff') format('woff'),
        url('../fonts/roboto/Roboto-Italic/Roboto-Italic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Italic/Roboto-Italic.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-light';
    src: url('../fonts/roboto/Roboto-Light/Roboto-Light.eot');
    src: url('../fonts/roboto/Roboto-Light/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Light/Roboto-Light.woff') format('woff'),
        url('../fonts/roboto/Roboto-Light/Roboto-Light.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Light/Roboto-Light.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-light-italic';
    src: url('../fonts/roboto/Roboto-LightItalic/Roboto-LightItalic.eot');
    src: url('../fonts/roboto/Roboto-LightItalic/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-LightItalic/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/roboto/Roboto-LightItalic/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-LightItalic/Roboto-LightItalic.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-medium';
    src: url('../fonts/roboto/Roboto-Medium/Roboto-Medium.eot');
    src: url('../fonts/roboto/Roboto-Medium/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Medium/Roboto-Medium.woff') format('woff'),
        url('../fonts/roboto/Roboto-Medium/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Medium/Roboto-Medium.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-medium-italic';
    src: url('../fonts/roboto/Roboto-MediumItalic/Roboto-MediumItalic.eot');
    src: url('../fonts/roboto/Roboto-MediumItalic/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-MediumItalic/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/roboto/Roboto-MediumItalic/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-MediumItalic/Roboto-MediumItalic.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto';
    src: url('../fonts/roboto/Roboto-Regular/Roboto-Regular.eot');
    src: url('../fonts/roboto/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Regular/Roboto-Regular.woff') format('woff'),
        url('../fonts/roboto/Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Regular/Roboto-Regular.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-thin';
    src: url('../fonts/roboto/Roboto-Thin/Roboto-Thin.eot');
    src: url('../fonts/roboto/Roboto-Thin/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-Thin/Roboto-Thin.woff') format('woff'),
        url('../fonts/roboto/Roboto-Thin/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-Thin/Roboto-Thin.svg') format('svg');
}


@font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'roboto-thin-italic';
    src: url('../fonts/roboto/Roboto-ThinItalic/Roboto-ThinItalic.eot');
    src: url('../fonts/roboto/Roboto-ThinItalic/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto-ThinItalic/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/roboto/Roboto-ThinItalic/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/roboto/Roboto-ThinItalic/Roboto-ThinItalic.svg') format('svg');
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/fonts/icomoon.eot?pm5zpr');
    src: url('../fonts/icomoon/fonts/icomoon.eot?pm5zpr#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/fonts/icomoon.ttf?pm5zpr') format('truetype'),
        url('../fonts/icomoon/fonts/icomoon.woff?pm5zpr') format('woff'),
        url('../fonts/icomoon/fonts/icomoon.svg?pm5zpr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}