@import "../style/variables";

.Container {
  display: flex;
  margin-bottom: 15px;
  align-items: stretch;
}

.Left {
  &.Centerline .Header {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.Centerline .LeftContent {
  border-right: 1px solid $pink;
}

.Left,
.Right {
  flex: 1;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}

.Header {
  padding: 5px;
  color: white;
  font-size: 14px;
  text-align: center;
  background-color: $pink;
}

.Centerline {
  .LeftContent,
  .RightContent {
    padding: 15px;
    background-color: white;
    flex-grow: 1;
  }
}
