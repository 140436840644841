@import "../style/variables";

.ActionLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: $pink;
}

.ActionLink:hover,
.ActionLink:focus {
  text-decoration: underline;
}
