
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  background-color: transparent;
  border: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-flag-en-uk .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-flag-en-uk .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(189, 0, 52);
}
.icon-flag-en-uk .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(189, 0, 52);
}
.icon-flag-en-uk .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(189, 0, 52);
}
.icon-flag-en-uk .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(189, 0, 52);
}
.icon-flag-en-uk .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(26, 35, 123);
}
.icon-flag-en-uk .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(189, 0, 52);
}
.icon-flag-en-us .path1:before {
  content: "\e90e";
  color: rgb(203, 0, 28);
}
.icon-flag-en-us .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-flag-en-us .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(42, 53, 96);
}
.icon-flag-en-us .path4:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-star:before {
  content: "\e912";
}
.icon-payment:before {
  content: "\e913";
}
.icon-chat:before {
  content: "\e914";
}
.icon-save:before {
  content: "\e915";
}
.icon-gear-star:before {
  content: "\e916";
}
.icon-info:before {
  content: "\e917";
}
.icon-star-hollow:before {
  content: "\e918";
}
.icon-my-brian:before {
  content: "\e919";
}
.icon-star-stylized-half:before {
  content: "\e91a";
}
.icon-arrow-down-angular:before {
  content: "\e91b";
}
.icon-arrow-upangular:before {
  content: "\e91c";
}
.icon-arrow-left-angular:before {
  content: "\e91d";
}
.icon-arrow-right-angular:before {
  content: "\e91e";
}
.icon-head-star:before {
  content: "\e91f";
}
.icon-cross:before {
  content: "\e920";
}
.icon-linear-lock:before {
  content: "\e921";
}
.icon-linear-mail:before {
  content: "\e922";
}
.icon-linear-phone:before {
  content: "\e923";
}
.icon-linear-user:before {
  content: "\e924";
}
.icon-star-stylized:before {
  content: "\e925";
}
.icon-brian:before {
  content: "\e926";
}
.icon-gear:before {
  content: "\e927";
}
.icon-mike:before {
  content: "\e928";
}
.icon-brian-without-badge:before {
  content: "\e929";
}
.icon-brian-with-badge:before {
  content: "\e92a";
}
.icon-alarm:before {
  content: "\e92b";
}
.icon-chat-small:before {
  content: "\e92c";
}
.icon-file:before {
  content: "\e92d";
}
.icon-rabbit:before {
  content: "\e92e";
}
.icon-turtle:before {
  content: "\e92f";
}
.icon-euro:before {
  content: "\e930";
}
.icon-clock:before {
  content: "\e931";
}
.icon-info-hollow:before {
  content: "\e932";
}
.icon-arrow-down-small:before {
  content: "\e933";
}
.icon-arrow-up-small:before {
  content: "\e934";
}
.icon-arrow-left-small:before {
  content: "\e935";
}
.icon-arrow-right-small:before {
  content: "\e936";
}
.icon-arrow-up:before {
  content: "\e937";
}
.icon-arrow-down:before {
  content: "\e938";
}
.icon-arrow-right:before {
  content: "\e939";
}
.icon-arrow-left:before {
  content: "\e93a";
}
.icon-bin:before {
  content: "\e9ac";
}
