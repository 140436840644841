@import "../style/variables";

.Loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: $dark;
}