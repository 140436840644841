@import '../style/variables';

.StatusContainer {
  background: $grey-1;
  font-size: 0.8rem;
  color: $dark;
  min-height: 2rem;
  padding: 0.2rem 0;
  margin: 0 !important;
}
.VerticalAlign {
  align-self: center;
}
.AwaitingContainer {
  @extend .StatusContainer;
  &:before {
    content: '\e931';
    font-family: 'icomoon';
    color: $grey-4;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 15px;
    margin-left: 26px;
  }
}

.Avatar {
  margin-left: 0.1rem;
  margin-right: 0.2rem;
  border-radius: 50%;
  vertical-align: middle;
  background: $grey-6;
  width: 2rem;
  height: 2rem;
}

.NeedsApproval,
.Overdue {
  align-content: flex-end;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  margin: 0.2rem 0.5rem;
  background: white;
}

.NeedsApproval {
  color: white;
  background: $pink;
}

.Overdue {
  color: $orange;
  border: 1px solid $orange;
}
