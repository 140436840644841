@import "../style/variables";

.Container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.LoginBox {
  padding: 2em;
  border-radius: 15px;
  background: $pink;
  text-align: center;
  color: white;
  position: relative;
  z-index: 2;

  &::after {
    content: '';

    border-radius: 15px;
    top: 0;
    left: 0;
    position: absolute;

    width: 100%;
    height: 100%;
    box-shadow: 0 0 32px 0 rgba(255, 95, 107, 0.75);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }
}

.Logo {
  color: white;
  font-size: 75px;
}
