// colors
$pink: #ff5f6b;
$pink-hover: rgba(255, 95, 107, 0.7);
$grey-1:#f4f4f4;
$grey-2: #ededed;
$grey-3:#e5e5e5;
$grey-4: #dddddd;
$grey-5: #cacaca;
$grey-6: #a1a1a1;
$grey-7: #626262;
$grey-8: #828282;
$dark: #525252;
$orange: #f7af2c;
$orange-hover: rgba(247, 175, 44, 0.7);
$green: #7de08a;
$green-hover: rgba(125, 224, 138, 0.7);
$error: #ff5f6b;
$warning: #5fdacd;



// sizes
$header-height: 60px;
$margin: 15px;
$width: 360px;
$height: 640px;
$button-height: 48px;
$field-height: 40px;
$max-width: 960px;

// durations
$tl: 450ms;
$tm: 300ms;
$ts: 150ms;

// other
// $box-shadow: 0 0 9px 0 rgba(0,0,0,.1);
$box-shadow: 0 1px 3px 1px rgba(204, 204, 204, 0.3);


// common breakpoints
$break-phone : 576px;
$break-tablet : 768px;
$break-desktop : 992px;
$break-large : 1200px;