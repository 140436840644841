@import "./variables";
@import "./fonts";
@import "./icomoon";

$font-family-sans-serif: roboto,
sans-serif;
$link-hover-decoration: none;
$body-bg: $grey-1;
$theme-colors: ("primary": $pink,
  "danger": $orange);

$body-color:$grey-8;

.basic-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.box-spe {
  display: flex;
  min-width: 6rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
}

@import "~bootstrap/scss/bootstrap";
@import '~react-toastify/dist/ReactToastify.css';
@import "./custom-style";