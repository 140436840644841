@import '../style/variables';

.Wrapper {
  min-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0.5rem 0;
  padding: 0.3rem;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}

.StatusContainer {
  border-bottom: 1px solid $grey-3;
  padding: 0;
  background: white;
  min-height: 57px;
}

.FilesContainer {
  display: flex;
  flex-direction: row;
  min-width: 100%;
}
.OrderInfo {
  // display: flex;
  // flex-direction: row;
  color: black;
  font-size: 0.8rem;
  padding: 5px 0;
  flex: 0 1 30%;

  &:before {
    font-family: 'icomoon';
    color: $grey-3;
    display: block;
    font-size: 22px;
    margin-bottom: 4px;
  }

  &-text:before {
    content: '\e92d';
  }

  img {
    margin: 0 auto;
  }
}
.ToggleDocuments {
  align-self: center;
  .toggleUpload,
  .toggleText {
    cursor: pointer;
    &:after {
      font-family: 'icomoon';
      font-size: 0.8rem;
    }
  }
  .toggleText {
    &:after {
      content: '\e917';
    }
  }
  .toggleUpload {
    &:after {
      content: '\e92d';
    }
  }
}
.UploadInfo {
  color: black;
  font-size: 10px;
  padding: 5px;
  max-width: 150px;
  flex-shrink: 1;
  text-overflow: ellipsis;
  i {
    margin: 0 auto 0 0;
  }
}

.OrderId {
  font-weight: bold;
  padding-left: 0.5rem;
  color: $pink;
  align-self: center;
}

.Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  text-align: left;
  padding: 5px 12px;
  overflow: hidden;
  color: $grey-8;
  min-width: 0;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.Price {
  align-self: center;
  padding-right: 0.8rem;
  text-align: right;
  font-size: 0.9rem;
  font-weight: bold;
  .Inner {
    &:after {
      content: '\e930';
      font-family: 'icomoon';
      margin-left: 5px;
      font-size: 0.8rem;
    }
    &.discounted {
      text-decoration: line-through;
    }
  }
}

.Timing {
  align-self: center;
  font-size: 0.9rem;
  .Inner {
    &:before {
      content: '\e931';
      font-family: 'icomoon';
      margin-right: 0.15rem;
      font-size: 0.8rem;
    }
  }
}

// .Arrow {
//   padding: 0px 10px 5px 5px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   flex: 0 1 25px;
//   font-size: 24px;
//   font-family: 'icomoon';
//   color: $pink;
//   text-align: center;
//   vertical-align: middle;

//   &:before {
//     content: '\e91e';
//     vertical-align: middle;
//   }
// }
