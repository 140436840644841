@import "../style/variables";

.Card {
  padding: 3px;
  border-bottom: 1px solid $grey-3;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.FullWidthCard {
  @extend .Card;
  width: 100%;
  max-width: 80em;
  clear: both;
  float: none;

  .Title {
    text-align: left;
    padding-left: 1em;
  }
}

.Title {
  padding-top: 1em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid $grey-3;
  font-family: "roboto-bold";
  color: $grey-8;
}

.Action {
  float: right;
  padding-right: 1em;
}

.Content {
  padding: 1em;
}
