@import '../style/variables';

.Container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  div {
    margin-right: 1em;
  }

  dt {
    font-weight: bold;
    margin-top: 0.5em;
  }

  dd {
    margin: 0;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
}

.NeedsApproval,
.Overdue {
  float: none;
  display: inline-block;
  border-radius: 10px;
  padding: 2px 10px;
  margin: -2px 5px;
  min-width: 300px;
}

.NeedsApproval {
  color: white;
  background: $pink;

  &:hover {
    color: white;
    background-color: lighten($pink, 5);
  }
}

.Overdue {
  color: $orange;
  border: 1px solid $orange;
}
