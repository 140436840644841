@import '../style/variables';
.spe {
  display: flex;
  min-width: 6rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
  border: 1px solid $grey-5;

  &:hover {
    color: $grey-6;
    transition: all ease-in-out 0.3s;
  }

  span {
    padding: 0 0.2rem;
  }
}
