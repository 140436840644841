@import '../style/variables';

.languageCouple {
  display: flex;
  min-width: 6rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  position: relative;
  cursor: pointer;

  &:hover {
    color: $grey-6;
    transition: all ease-in-out 0.3s;
  }

  span {
    padding: 0 0.2rem;
  }

  &.VALIDATED {
    border: 1px solid $green;
  }

  &.PENDING {
    border: 1px solid orange;
  }

  &.REMOVED {
    border: 1px solid $pink;
  }
}

.icon {
  margin: auto 1rem auto 0.5rem;

  &.VALIDATED {
    color: $green;

    &:hover {
      color: $green-hover;
    }
  }

  &.PENDING {
    color: $orange;

    &:hover {
      color: $orange-hover;
    }
  }

  &.REMOVED {
    color: $pink;

    &:hover {
      color: $pink-hover;
    }
  }

  &.small {
    font-size: 1rem;
  }

  &:hover {
  }
}

.ReactModal {
  border: 1px solid $pink !important;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;

  background-color: #fff;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.5);

  .ModalContainer {
    margin: 10px;
    margin-bottom: 20px;
  }

  .CloseModalButton {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    color: $pink;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: $pink-hover;
      transition: all ease 0.3s;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.error {
  color: $error;
}
