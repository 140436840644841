@import '../style/variables';

.Card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 5px 15px 0 hsla(0, 0%, 0%, 0.15);
  border-radius: 5px;
  margin: 5px;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: hsla(0, 0%, 0%, 0.05);
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  z-index: 1000;
}

.Image {
  width: 40px;
  height: 40px;
  margin: 0 1rem;
  border-radius: 50%;
  justify-self: center;
}

.NameEmail {
  flex: 1;
}

.Email {
  font-style: italic;
  font-size: 0.9rem;
}
.Account {
  color: $grey-8;
  position: relative;
  .Meta {
    color: $pink;
    font-size: 0.9rem;
    span:first-of-type {
      margin-right: 1rem;
    }
  }
}

.Nationality {
  padding-left: 5px;
  img {
    vertical-align: middle;
  }
}

.Meta {
  color: $pink;
  font-size: 0.9rem;
}

.removeButton {
  position: absolute;
  top: 0.3rem;
  right: 1.7rem;
  font-size: 1.5rem;
  color: $pink;
  &:before {
    content: '×';
  }
  &:hover {
    color: $pink-hover;
  }
}
