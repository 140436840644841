// custom style overriding  global styling bootstrap
h2 {
  font-size: 1.4rem;
  color: $pink;
}

h3 {
  font-size: 1.2rem;
  color: $grey-8;
}

ul {
  list-style: none;
}
.pink {
  color: $pink;
}
// Modal style :
.modal-title {
  font-size: 1.4rem;
  color: $pink;
}

.btn-group {
  margin: 0 1rem;
  .btn {
    &.active {
      background-color: $pink !important;
    }
    &:hover {
      background-color: $pink-hover !important;
    }
  }
}

.row-eq-height {
  div[class*='col-'] {
    margin-bottom: 1.5rem;
    div[class*='Card_'] {
      min-height: 100%;
    }
  }
}

label.error {
  font-size: 0.8rem;
  color: $pink;
}
.nav-link {
  cursor: pointer;
}


.flex {
  display: flex;
}

.w-full {
  width: 100%;
} 

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-cbetween;
}